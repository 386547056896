<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>听书管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/audioBooks' }">
                    音频书列表
                </el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">说明</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">封面大小为：254*352</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="mini">

                <el-form-item label="类型：" label-position="left" class="input" prop="audio_books_cate_id">
                    <el-select style="width: 200px" v-model="form.audio_books_cate_id" placeholder="请选择">
                        <el-option v-for="item in cate" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="名称: " label-position="left" class="input" prop="name">
                    <el-input style="width: 200px" v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="有效天数: " label-position="left">
                    <el-input-number v-model="form.days" :min="1"></el-input-number>
                </el-form-item>



                <el-form-item label="封面：" label-position="left" prop="cover">
                    <select-images @onSelect="onSelectImage" @onDelete="onDelete" :image="image"></select-images>
                </el-form-item>

                <el-form-item label="作者: " label-position="left" class="input" prop="author">
                    <el-input style="width: 200px" v-model="form.author"></el-input>
                </el-form-item>
                <el-form-item label="概要，概述">
                    <el-input type="textarea" v-model="form.desc"></el-input>
                </el-form-item>
                <el-form-item label="是否免费: " label-position="left">
                    <el-radio v-model="form.is_free" :label="1">是</el-radio>
                    <el-radio v-model="form.is_free" :label="0">否</el-radio>
                </el-form-item>

                <el-form-item label="会员免费: " label-position="left" v-if="form.is_free != 1">
                    <el-checkbox v-model="form.vip_free"></el-checkbox>
                </el-form-item>

                <el-form-item label="价格: " label-position="left" v-if="form.is_free != 1">
                    <el-input-number v-model="form.price" :min="1"></el-input-number>
                </el-form-item>

                <el-form-item label="会员价格: " label-position="left" v-if="form.is_free != 1&!form.vip_free">
                    <el-input-number v-model="form.vip_price" :min="1"></el-input-number>
                </el-form-item>

                <el-form-item label="是否推荐: " label-position="left">
                    <el-radio v-model="form.is_recommend" :label="1">是</el-radio>
                    <el-radio v-model="form.is_recommend" :label="0">否</el-radio>
                </el-form-item>

                <el-form-item label="状态: " label-position="left">
                    <el-radio v-model="form.status" :label="1">上架</el-radio>
                    <el-radio v-model="form.status" :label="0">下架</el-radio>
                </el-form-item>

                <el-form-item label="内容：" label-position="left" prop="intro">
                    <editor @onChange="onChange" :content="form.intro"></editor>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" @click="onSubmit">
                        保存
                    </el-button>
                    <el-button style="margin-left: 10px" size="small" @click="$router.back()">
                        取消
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
  
<script>
import SelectImages from "../../../components/selectImages/index";
import editor from '../../../components/editor'
import { mapActions } from 'vuex'
export default {
    name: 'Add',
    components: { SelectImages, editor },
    data() {
        return {
            image: '',
            imageUrl: config.imageUrl,
            id: 0,
            loading: false,
            uploadPath: 'class',
            cate: [],
            form: {},
            rules: {
                name: [{ required: true, trigger: 'blur', message: '请输入名称' }],
                author: [{ required: true, trigger: 'blur', message: '请输入作者名称' }],
                cover: [{ required: true, trigger: 'change', message: '请上传封面' }],
                audio_books_cate_id: [{ required: true, trigger: 'change', message: '请上传封面' }],
            }
        }
    },
    methods: {

        onDelete() {
            this.image = ''
        },
        onSelectImage(image) {
            this.image = imageUrl + image.path
            this.form.cover = image.path
        },
        onChange(content) {
            this.form.intro = content
        },

        ...mapActions('hearBook', ['getAllCate', 'setAudioBooksAdd', 'getAudioBooksGet']),
        async initData() {
            this.loading = true
            const { res_info, data } = await this.getAllCate()
            const res = await this.getAudioBooksGet(this.id)
            res.data.vip_free = res.data.vip_free == 1 ? true : false
            this.form = res.data
            if (res_info != 'ok') return
            this.cate = data
            this.loading = false
        },

        onSubmit() {
            this.$refs.form.validate(valid => {
                if (!valid) return
                this.addClass(this.form)
            })
        },
        async addClass(form) {
            this.loading = true
            form.id = this.id
            form.vip_free = form.vip_free == false ? 0 : 1
            const { res_info } = await this.setAudioBooksAdd(form)
            this.loading = false
            if (res_info != 'ok') return
            this.$router.go(-1)
            this.$message.success('编辑成功！')
        },
    },
    created() {
        this.id = this.$route.params.id
        this.initData()
    },
}
</script>
  
<style>
.el-tag+.el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>
  